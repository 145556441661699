.trailContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999; /* Ensure it's on top */
}

.particle {
  position: absolute;
  width: 32px;
  height: 32x;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  pointer-events: none;
/*   filter: drop-shadow(0 0 5px currentColor); */
}
